// import 'intl-tel-input/build/css/intlTelInput.min.css';

import "./utils";

import "./swiper";
import "./popup";
import "./accordeon";
// import "./search";

import "../css/main.scss";
