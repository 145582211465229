import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const newsSlider = new Swiper(".news-slider", {
  slidesPerView: "auto",
  spaceBetween: 16,
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
    992: {
      spaceBetween: 20,
      slidesPerView: 3,
    },
  },

  autoHeight: true,
});
